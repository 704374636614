var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DepartmentSelectorModal", {
        attrs: {
          show: _vm.departmentSelectorShow,
          preselected: _vm.value
            ? _vm.value
                .map(function (v) {
                  return v.uuId
                })
                .join("|")
            : "",
          allowNone: true,
          enablePagination: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.departmentSelectorShow = $event
          },
          cancel: _vm.departmentSelectorCancel,
          ok: _vm.departmentSelectorOk,
          delete: _vm.departmentSelectorDeleted,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }