var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" }, attrs: { id: _vm.id } },
    [
      _vm.enableDelete
        ? [
            _c("span", { attrs: { id: "TASK_TREE_BTN_DELETE" } }, [
              _c(
                "button",
                {
                  staticClass: "btn-action mb-2",
                  attrs: { disabled: _vm.selected.length === 0 },
                  on: { click: _vm.rowDelete },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "trash-can"] },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "TASK_TREE_BTN_DELETE",
                  triggers: "hover",
                  placement: "top",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
            ),
          ]
        : _vm._e(),
      _vm.enableDelete
        ? [
            _c("span", { attrs: { id: "TASK_TREE_BTN_CLEAR" } }, [
              _c(
                "button",
                {
                  staticClass: "btn-action mb-2",
                  attrs: { disabled: _vm.disableClearFilter },
                  on: { click: _vm.clearFilters },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "filter-circle-xmark"] },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "TASK_TREE_BTN_CLEAR",
                  triggers: "hover",
                  placement: "top",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("button.clear_filters")) + " ")]
            ),
          ]
        : _vm._e(),
      _vm.existingData !== null
        ? [
            _c("span", { attrs: { id: "TASK_TREE_BTN_EXIST" } }, [
              _c(
                "button",
                {
                  staticClass: "btn-action mb-2",
                  on: { click: _vm.checkExists },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "arrow-right-arrow-left"] },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "TASK_TREE_BTN_EXIST",
                  triggers: "hover",
                  placement: "top",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("button.exist")) + " ")]
            ),
          ]
        : _vm._e(),
      _c("span", { staticClass: "total-tasks" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                _vm.projectId !== null ? "task.total" : "task.total_values",
                [_vm.totalTasks]
              )
            ) +
            " "
        ),
      ]),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham",
        style: "width: 100%;height: " + _vm.height,
        attrs: {
          id: "preview-grid",
          gridOptions: _vm.gridOptions,
          animateRows: "",
          autoGroupColumnDef: _vm.autoGroupColumnDef,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          groupDefaultExpanded: "",
          rowData: _vm.rowData,
          rowSelection: _vm.multiple ? "multiple" : "single",
          rowMultiSelectWithClick: "",
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressCellFocus: "",
          suppressContextMenu: "true",
          suppressMultiSort: "",
          singleClickEdit: "",
          treeData: "",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.deleteMessage) + " "),
          ]),
        ]
      ),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.durationCalculationShow,
          taskName: _vm.durationCalculation.taskName,
          defaultActionForNonWorkPrompt:
            _vm.durationCalculation.defaultActionForNonWorkPrompt,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          showApplyAllCheckbox: "",
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projectScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          projectStartDateStr: _vm.durationCalculation.projectStartDateStr,
          projectCloseDateStr: _vm.durationCalculation.projectCloseDateStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
        },
        on: {
          "update:show": function ($event) {
            _vm.durationCalculationShow = $event
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          skip: function ($event) {
            return _vm.durationCalculationOk({ skip: true })
          },
          cancel: _vm.durationCalculationCancel,
          calendarChange: _vm.durationCalculationCalendarChange,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }