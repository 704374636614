var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "file-to-import-modal",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            size: "md",
            visible: _vm.show,
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.downloading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        variant: "success",
                        disabled:
                          _vm.showFileValidationError ||
                          (_vm.file === null && _vm.url === null) ||
                          _vm.downloading,
                      },
                      on: { click: _vm.modalOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", {
            staticClass: "w-100",
            attrs: { slot: "modal-title" },
            domProps: { innerHTML: _vm._s(_vm.title) },
            slot: "modal-title",
          }),
          _c(
            "b-tabs",
            { attrs: { "content-class": "mt-3", pills: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: _vm.$t("task.import.file") } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "field-file",
                        label: "File",
                        "label-for": "file-import",
                      },
                    },
                    [
                      _c("b-form-file", {
                        attrs: {
                          id: "file-import",
                          state: _vm.state.pristine ? null : Boolean(_vm.file),
                          placeholder: "Choose a file...",
                          "drop-placeholder": "Drop file here...",
                          "browse-text": "Browse",
                        },
                        model: {
                          value: _vm.file,
                          callback: function ($$v) {
                            _vm.file = $$v
                          },
                          expression: "file",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showFileValidationError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t("task.alert.filetype_not_supported")
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: _vm.$t("task.import.url_title") } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "field-url",
                        label: _vm.$t("task.import.url_title"),
                        "label-for": "file-url-import",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "file-url-import",
                          placeholder: _vm.$t("task.import.url"),
                        },
                        model: {
                          value: _vm.url,
                          callback: function ($$v) {
                            _vm.url = $$v
                          },
                          expression: "url",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showUrlError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.show
        ? _c("GanttImportMapperDialog", {
            attrs: {
              show: _vm.state.importMapperShow,
              source: _vm.importFile,
              projectId: _vm.projectId,
              properties: _vm.properties,
              existingData: _vm.existingData,
              mode: _vm.mode,
            },
            on: {
              "modal-ok": _vm.importMapperOk,
              "modal-cancel": _vm.importMapperCancel,
            },
          })
        : _vm._e(),
      _c("GanttErrorDialog", {
        attrs: { show: _vm.state.errorDialogShow, msg: _vm.errorDialogMessage },
        on: { "modal-ok": _vm.errorDialogOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }