

<template>
  <div>
    <DepartmentSelectorModal :show.sync="departmentSelectorShow" @cancel="departmentSelectorCancel" @ok="departmentSelectorOk" :preselected="value ? value.map(v => { return v.uuId }).join('|') : ''" :allowNone="true" :enablePagination="false" @delete="departmentSelectorDeleted"/>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'DepartmentEditor',
  components: {
    DepartmentSelectorModal: () => import('@/components/modal/DepartmentSelectorModal')
  },
  data() {
    return {
      value: '',
      departmentSelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    departmentSelectorOk({ details }) {
      const newDepartment = details.map(i => { return { uuId: i.uuId, name: i.name}});
      this.params.data.company = details[0].company;
      
      if (newDepartment.length === 0) {
        this.value = [];
        this.closePicker();
      }
      this.value = newDepartment;
      this.closePicker();
    },
    departmentSelectorCancel() {
      this.closePicker();
    },
    departmentSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    this.value = this.params.value;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>