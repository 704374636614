var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CustomerSelectorModal", {
        attrs: {
          show: _vm.customerSelectorShow,
          preselected: _vm.value ? _vm.value.uuId : "",
          allowNone: true,
          enablePagination: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.customerSelectorShow = $event
          },
          cancel: _vm.customerSelectorCancel,
          ok: _vm.customerSelectorOk,
          delete: _vm.customerSelectorDeleted,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }