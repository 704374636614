

<template>
  <div>
    <CompanySelectorModal :show.sync="companySelectorShow" @cancel="companySelectorCancel" @ok="companySelectorOk" :preselected="value ? value.uuId : ''" :allowNone="true" :enablePagination="false" @delete="companySelectorDeleted"/>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'CompanyEditor',
  components: {
    CompanySelectorModal: () => import('@/components/modal/CompanySelectorModal')
  },
  data() {
    return {
      value: '',
      companySelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    companySelectorOk({ details }) {
      const newCompany = details.map(i => { return { uuId: i.uuId, name: i.name}});

      if (newCompany.length === 0) {
        this.value = null;
        this.closePicker();
      }
      
      if (this.value && this.value.uuId !== newCompany[0].uuId) {
        this.params.data.department = null;
      }
      
      this.value = newCompany[0];
      this.closePicker();
    },
    companySelectorCancel() {
      this.closePicker();
    },
    companySelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    this.value = this.params.value;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>