

<template>
  <div>
    <CustomerSelectorModal :show.sync="customerSelectorShow" @cancel="customerSelectorCancel" @ok="customerSelectorOk" :preselected="value ? value.uuId : ''" :allowNone="true" :enablePagination="false" @delete="customerSelectorDeleted"/>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'CustomerEditor',
  components: {
    CustomerSelectorModal: () => import('@/components/modal/CustomerSelectorModal')
  },
  data() {
    return {
      value: '',
      customerSelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    customerSelectorOk({ details }) {
      const newCustomer = details.map(i => { return { uuId: i.uuId, name: i.name}});

      if (newCustomer.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newCustomer[0];
      this.closePicker();
    },
    customerSelectorCancel() {
      this.closePicker();
    },
    customerSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    this.value = this.params.value;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>