

<template>
  <div>
    <ProjectSelectorModal :show.sync="projectSelectorShow" @cancel="projectSelectorCancel" @ok="projectSelectorOk" :preselected="value ? value.uuId : ''" :allowNone="true" :enablePagination="false" @delete="projectSelectorDeleted"/>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ProjectEditor',
  components: {
    ProjectSelectorModal: () => import('@/components/modal/ProjectSelectorModal')
  },
  data() {
    return {
      value: '',
      projectSelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    projectSelectorOk({ details }) {
      const newProject = details.map(i => { return { uuId: i.uuId, name: i.name, stages: i.stageList.map(s => { return { value: s.name, text: s.name }}), autoScheduling: i.autoScheduling}});

      if (newProject.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newProject[0];
      this.closePicker();
    },
    projectSelectorCancel() {
      this.closePicker();
    },
    projectSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    this.value = this.params.value;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>